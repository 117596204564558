import "./App.module.css";
import Contacts from "./Contacts";
import Qualifications from "./Qualifications";
import Services from "./Services";
import styles from "./App.module.css";
import avatar from "./assets/jurate-marclay.jpg";

function App() {
  return (
    <div className={styles["app"]}>
      <div className={styles["title"]}>
        <h1>Jurate Marclay</h1>
        <img src={avatar} alt="Jurate Marclay" className={styles["image"]} />
      </div>
      <div className={styles["row"]}>
        <Services />
        <div className={styles["personal-info"]}>
          <Contacts />
          <Qualifications />
        </div>
      </div>
    </div>
  );
}

export default App;
