import styles from "./Qualifications.module.css";

function Qualifications() {
  return (
    <div className={styles["qualifications"]}>
      <h2>Expérience professionnelle</h2>
      <p>Formation de base : physiothérapeute reconnue par CRS.</p>
      <p>Formations complémentaires :</p>
      <ul>
        <li>Psychogénéalogie</li>
        <li>Accompagnement spirituel ignatien</li>
        <li>Bibliodrama</li>
        <li>Supervision</li>
      </ul>
    </div>
  );
}

export default Qualifications;
