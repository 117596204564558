import styles from "./Contacts.module.css";

function Contacts() {
  return (
    <div className={styles["contact-info"]}>
      <h2>Contacts</h2>
      <ul>
        <li>
          <label htmlFor="address">Adresse: </label>
          <span id="address">15 ch Maurice Ravel 1290 Versoix, GE</span>
        </li>
        <li>
          <label htmlFor="phone">Numéro de téléphone: </label>
          <span id="phone">
            <a href="tel:+41795865095">+41795865095</a>
          </span>
        </li>
        <li>
          <label htmlFor="email">Email: </label>
          <span id="email">
            <a href="mailto:jurate.stucinskaite@gmail.com">
              jurate.stucinskaite@gmail.com
            </a>
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Contacts;
